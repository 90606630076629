$(document).ready(function () {

	$(function mainForm() {
		// console.log("[mainForm]");
		// Form variables
		var $body = $('body'),
		$mainForm = $('#mainForm'),
		$nameField = $('input[name="name"]'),
		$mailField = $('input[name="emailAddress"]'),
		$messageField = $('textarea[name="message"]'),
		$sendForm = $('button[name="sendInfo"]');

		/**** Error messages configuration ****/
		errorText = $mainForm.attr('data-error') ? $mainForm.attr('data-error') : "Fill in the required empty fields and make sure the information is valid.";
		successText = $mainForm.attr('data-success') ? $mainForm.attr('data-success') : "Your message has been sent, we will contact you as soon as possible.";
		
		$body.append('<div id="txtError" class="form-error d-none form-note form-alert">' + errorText + '</div>');
		$body.append('<div id="txtSuccess" class="form-success d-none form-note form-confirm">' + successText + '</div>');


		/**** Error messages and scroll movements ****/
		// Error message
		function errorMessage() {       
			$showError = $body.find('.form-error').fadeIn(1000).delay(3000).fadeOut(1000).removeClass('d-none');
		};

		// Success message
		function successMessage() {     
			$showSuccess = $body.find('.form-success').fadeIn(1000).delay(5000).fadeOut(1000).removeClass('d-none');
		};

		// Scroll to first error and focus field
		function scrollFirstError() {
			var firstError = $mainForm.find('.field-error:first');     
			if(firstError.length){
				$('html, body').stop(true).animate({
					scrollTop: (firstError.offset().top - 100)
				}, 1200, function(){firstError.focus();});
			}
		};

			/**** Validate fields ****/
			// Regex to validate name
			var expRegNombre = /^[a-zA-ZÑñÁáÉéÍíÓóÚúÜü\s]+$/;
			// Regex to validate email
			var email_regex = /(.+)@(.+){2,}\.(.+){2,}/;
			// Regex to detect whitespaces only
			var whitespace_regex = /[^\s\\]/;

			// Send form - Validate empty fields or incorrect info when click send button
			$sendForm.on('click', function(e) {

					// Validate name
					if ( $nameField.val() == "" || !whitespace_regex.test($nameField.val()) || !expRegNombre.exec($nameField.val()) ) {
						// console.log("[mainForm][validateName]");
						$nameField.addClass('field-error');
					}

					// Validate email
					if ($mailField.val() == "" || !email_regex.test($mailField.val())){
						// console.log("[mainForm][validateEmail]");
						$mailField.addClass('field-error');
					}

					// FIRST ALERT - Search if exist any error and show error message and scrooll to the error
					if ($mainForm.find('.field-error').length){
							//console.log("Find a class field-error");
							// console.log("[mainForm][findError]");
							errorMessage();
							scrollFirstError();
							return false;   
					}
					else {
							//console.log('Valid information, ready to be sent 2');
							// If the values are wrong, the form won't sent
							var dataString = {
								"Name" : $nameField.val(),
								"Mail" : $mailField.val(),
								"Message" : $messageField.val(),
							};

						/*************************************************************************************************************************************/

							// console.log('Valid information, ready to be sent 3');
							// console.log("Name", $nameField.val() );
							// console.log("Mail", $mailField.val() );
							// console.log("Message", $messageField.val() );
							
							$.ajax({
									type: "POST",
									url: "mail/send_mail.php",
									dataType: 'html',
									data: dataString,
									// beforeSend: function (){
									//  console.log('Form is going to send');
									// },
									
									success: function() {
										$(':input','#mainForm')
										.val('')
										.not(':button, :submit, :reset, :hidden')
										//console.log('Form sent');
										$(".submitArea").empty();
										successMessage();
										$(".submitArea").append('<div class="col-12 text-center"><h6>¡Mensaje enviado!</h6></div>');
										// console.log('Form sent successfully');
									},
									error: function() {
										alert('No se ha podido enviar su mensaje, intente más tarde.');
									}
									
							});

							}
							// Finish the proccess if 'else' not excecute
							return false;
					}); 
					// end : Send form 
	});
	// end : function

	/**** Remove error classes ****/
	// Remove class field-error from inputs
	$(document).on('input, paste, blur, keyup', '.field-error', function(){
		$(this).removeClass('field-error');
	});

	$('a[href*="#"]')
		.not('[href="#"]')
		.not('[href="#0"]')
		.click( function(event) {
			if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname	) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if ( target.length ) {
					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top - 200
					}, 1000, function() {
						var $target = $(target);
						$target.focus();
						if ( $target.is(":focus") ) {
							return false;
						} else {
							$target.attr('tabindex','-1');
							$target.focus();
						};
					});
				}
			}
		});

	$('#wa-float').floatingWhatsApp({
		phone: '525520722480',
		popupMessage: 'Hola, gracias por contactarnos. ¿Cómo podemos ayudarte?',
		message: 'Quiero saber más del producto',
		showPopup: true,
		headerTitle: 'Bienvenido',
		buttonImage: '<img src="img/svg/wa.svg" />',
		// position: 'right'
	});

	$(window).scroll( function() {
		var scroll = $(window).scrollTop();
		if (scroll >= 100) {
			$('#headerNav').addClass('fixed-top');
		} else {
			$('#headerNav').removeClass('fixed-top');
		}
	});

});


/**
 * @param String name
 * @return String
 */
var getParameterByName = function(name) {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
	results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

var responseMail = getParameterByName('r');

if( responseMail == 1) {
	$('.box-up').fadeIn(1000).delay(5000).fadeOut(1000).removeClass('d-none').addClass('form-note form-confirm');
	$('.box-up .container .row .col-12').append('<h6 class="mb-0">Tu mensaje ha sido enviado, nos pondremos en contacto a la brevedad posible.</h6>');
}
